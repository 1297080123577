<template>
  <admin>
    <page-header
      :back="{
        to: {
          name: 'dealers-account-maintenance.sales-consultants.index',
        },
        text: trans('Sales Consultants'),
      }"
    >
      <template v-slot:title><span v-text="trans('Upload Sales Consultants')"></span></template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <v-card>
          <v-card-title>File Information</v-card-title>
          <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      label="Upload File"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      outlined
                      hide-details
                      v-model="userUpload"
                      :rules="rules"
                    ></v-file-input>
                    <br />
                    <p>Click <a :href="sampleFileUrl" download>here</a> to the download sample file.</p>
                  </v-col>
                 </v-row>
              </v-form>
            <div class="d-flex justify-end my-6" flat tile>
              <v-btn
                large
                exact
                color="green darken-1"
                class="ma-1 white--text"
                @click="validate(false)"
                :loading="loadingSave"
                :disabled="loadingSave || loadingSaveAndExit"
              >
                <v-icon left>mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn
                large
                exact
                color="green lighten-1"
                class="ma-1 white--text"
                @click="validate(true)"
                :loading="loadingSaveAndExit"
                :disabled="loadingSave || loadingSaveAndExit"
              >
                <v-icon left>mdi-content-save</v-icon>
                Save and Exit
              </v-btn>
              <v-btn
                large
                exact
                color="warning"
                class="ma-1 white--text"
                :to="{ 
                  name: 'dealers-account-maintenance.sales-consultants.index',
                }"
              >
                <v-icon left>mdi-close</v-icon>
                Cancel
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </admin>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      sampleFileUrl:
        process.env.VUE_APP_API_BASE_URL.replace("api/", "") + "sample-files/sales-consultant.xlsx",
      userUpload: null,
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      rules: [(v) => !!v || "File is required", (v) => (v && v.size > 0) || "File is required"],
    };
  },
  methods: {
    ...mapActions({
      uploadSalesConsultant: "dealerSalesConsultant/uploadSalesConsultant",
    }),
    async validate(isExit) {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (isExit) {
        this.loadingSaveAndExit = true;
      } else {
        this.loadingSave = true;
      }

      await this.saveSaleConsultants(isExit);
    },

    async saveSaleConsultants(isExit) {
      var formData = new FormData();
      formData.append("file", this.userUpload);
      await this.uploadSalesConsultant(formData);

      this.$refs.form.reset();
      this.loadingSaveAndExit = false;
      this.loadingSave = false;
      if (isExit) {
        this.$router.push({
          name: 'dealers-account-maintenance.sales-consultants.index',
        });
      }
    },
  }
};
</script>

<style></style>
