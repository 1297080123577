var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('page-header',{attrs:{"back":{
      to: {
        name: 'dealers-account-maintenance.sales-consultants.index',
      },
      text: _vm.trans('Sales Consultants'),
    }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.trans('Upload Sales Consultants'))}})]},proxy:true}])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 col-12"},[_c('v-card',[_c('v-card-title',[_vm._v("File Information")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"Upload File","accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","outlined":"","hide-details":"","rules":_vm.rules},model:{value:(_vm.userUpload),callback:function ($$v) {_vm.userUpload=$$v},expression:"userUpload"}}),_c('br'),_c('p',[_vm._v("Click "),_c('a',{attrs:{"href":_vm.sampleFileUrl,"download":""}},[_vm._v("here")]),_vm._v(" to the download sample file.")])],1)],1)],1),_c('div',{staticClass:"d-flex justify-end my-6",attrs:{"flat":"","tile":""}},[_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"large":"","exact":"","color":"green darken-1","loading":_vm.loadingSave,"disabled":_vm.loadingSave || _vm.loadingSaveAndExit},on:{"click":function($event){return _vm.validate(false)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Save ")],1),_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"large":"","exact":"","color":"green lighten-1","loading":_vm.loadingSaveAndExit,"disabled":_vm.loadingSave || _vm.loadingSaveAndExit},on:{"click":function($event){return _vm.validate(true)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Save and Exit ")],1),_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"large":"","exact":"","color":"warning","to":{ 
                name: 'dealers-account-maintenance.sales-consultants.index',
              }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cancel ")],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }